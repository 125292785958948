// TODO: add passport slice data
import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { addresses, APP_STORAGE_EXPASS_MINT_BLOCK, APP_STORAGE_EXPASS_MINTS, APP_STORAGE_GET_COLLECTIBLEDETAILS, getAnynetStaticProvider, getGenesisBlockNumber, getNetwork, getSubgraphConfig, NetworkId, ZERO_ADDRESS } from "../constants";
import { CollectionType } from "../enums/collection.enum";
import { CATEGORIES, OfferType, PassportType } from "../enums/offers.enum";

import { getIPFSData, getIPFSLink } from "../helpers/ipfs";
import { GetAddCollectionMessage, GetAddSubscriptionPassportLogData, GetCollectionCreatedLogData, GetCollectionUpdatedLogData, GetCouponCollectionCreatedLogData, GetCreateCollectionMessage, GetCreateCouponCollectionMessage, GetDelinkCollectionMessage, GetLinkCollectionMessage, GetMarketPlaceTradablityMessage, GetRetireCollectionMessage, GetSubscriptionPassportDataMessage, GetUpdateCollectionDataMessage, GetUpdateCollectionDataUriMessage, GetUpdateCollectionDataUriOtherMessage } from "../helpers/MsgHelper";
import { ICollectibleDetail } from "../interfaces/ICollection.interface";
import { IPassportMintDetails } from "../interfaces/IPassport.interface";
import store, { RootState } from "../store";
import { ICollectionData, IExternalCollectionManager } from "../typechain/CollectionManager";
import { CollectionHelper__factory, CollectionManager__factory, DispatcherHelper__factory, Entity__factory, Loot8Collection__factory, Loot8POAPCollection__factory, Loot8TieredCouponCollection__factory, Loot8UniformCollection__factory, SubscriptionManager__factory, TokenPriceCalculator__factory, } from "../typechain/factories";
import { SendMetaTX } from "./AppSlice";
import { convertAreaData, getZeroArray, setAll } from "./helpers";
import { AddCollectionAsyncThunk, ICollectibleAsyncThunk, IMessageMetaData, ICollectionBaseAsyncThunk, AddFreeExPassAsyncThunk } from "./interfaces";
import { getAllWhitelistedCollectibleList } from "./collectibleSlice";
import { Wallet, ethers } from "ethers";
import { getCollectionFeedSettings, updateCollectionFeedSettings } from "../services/Message.service";
import { LogToConsole, LogToConsoleError } from "../helpers/Logger";
import { getData, removeData, storeData } from "../helpers/AppStorage";
// import { fetchCollectibleTransfers } from "../helpers/GraphQLHelper";
import { fetchCollectibleTransfers, fetchCollectionDetailsCombined } from "../helpers/GraphQLHelperSubgraph";
import { IDispatcher } from "../typechain/Dispatcher";
import { checkSuperAdmin } from "./EntitySlice";

//   TODO: 
// 1. mint passports
// 2. get passport details - done


export interface IPassportSliceData {
  // readonly AllPassportDetails: ICollectibleDetail [];
  readonly AllEntityPassportDetails: ICollectibleDetail[];
  readonly AllPassportMintDetails: IPassportMintDetails[];
  readonly IndividualWhitelistedCollections: any[];
  readonly loading: boolean;
  readonly mintDetailsLoading: boolean;
}



export const getAllCollectibleList = async ({ networkID, provider, collectibleType, entity }: { networkID, provider, collectibleType, entity }): Promise<IExternalCollectionManager.ContractDetailsStructOutput[]> => {
  const CollectionManager = CollectionManager__factory.connect(addresses[networkID].CollectionManager, provider);
  const availableCollectibles = await CollectionManager.getCollectionsForEntity(entity, collectibleType, false);
  return availableCollectibles;
}

export const getActiveReservation = async ({ networkID, provider, entity }: { networkID, provider, entity }): Promise<any> => {
  const dispatcherHelper = DispatcherHelper__factory.connect(addresses[networkID].DispatcherHelper, provider);
  const availableReservations = await dispatcherHelper.getActiveReservationsForEntity(entity);
  return availableReservations;
}


export const getCollectibleDetails = async ({ networkID, provider, userAddress, collectibleData, index, entityAddress, isSuperAdmin }: ICollectibleAsyncThunk, { isCache = true, fetchLatest = false }): Promise<ICollectibleDetail> => {

  let collectible: ICollectibleDetail = null;
  let collectibleAddress = collectibleData.source;
  let collectibleChainID = Number(collectibleData.chainId);

  const collectionManager = CollectionManager__factory.connect(addresses[networkID].CollectionManager, provider);
  const collectionHelper = CollectionHelper__factory.connect(addresses[networkID].CollectionHelper, provider);
  const loot8Collection = Loot8UniformCollection__factory.connect(collectibleAddress, getAnynetStaticProvider(collectibleChainID));
  const subscriptionManager = SubscriptionManager__factory.connect(addresses[networkID].SubscriptionManager, provider);
  let cachedCollectible = await getData(APP_STORAGE_GET_COLLECTIBLEDETAILS(collectibleAddress));


  const loot8CollectionOwnerAddress = await loot8Collection.owner();

  if (!(isSuperAdmin || userAddress.toLowerCase() == loot8CollectionOwnerAddress.toLowerCase())) {
    return
  }

  if (isCache && cachedCollectible && cachedCollectible?.entityAddress === entityAddress) {
    return cachedCollectible;
  }

  collectible = {
    index: index,
    dataURI: "",
    imageProps: {
      image: "",
      imageSize: 0
    },
    details: "",
    name: "",
    address: "",
    symbol: "",
    isActive: false,
    entityAddress: "",
    price: '0.00',
    priceRate: 0,
    start: null,
    end: null,
    category:CATEGORIES.OTHER,
    offerType: OfferType.NOTANYOFFER,
    collectionType: CollectionType.ANY,
    linkCollectible: [],
    subTitle: "",
    whitelistedCollections: [],
    totalSupply: 0,
    tokensEarned: 0,
    privateMessageCap: 0,
    maxMint: 0,
    maxPurchase: 0,
    chain: null,
    htmlTemplate: null,
    mintWithLinked: false,
    isPremium:false,
    isVideoCollectible: false,
    video: "",
    isCoupon: false,
    mintWithLinkedOnly: false,
    area: {
      latitude: "",
      longitude: "",
      radius: 0
    },
    maxBalance: 0,
    socialMedia: true,
    socialLinks: null,
    passportType: PassportType.REGULAR
  };

  let collectionAdditionalData: CollectionDataAdditional, collectionMetaData: CollectionMetadata, collectionData: CollectionData;
  
  let subgraphConfig = await getSubgraphConfig();

  if (subgraphConfig && subgraphConfig.modules && subgraphConfig.modules.collectibleDetails && !fetchLatest) {
    const combinedData = await fetchCollectionDetailsCombined(collectibleAddress);
    collectionData = combinedData?.collectionData;
    collectionAdditionalData = combinedData?.collectionDataAdditional;
    collectionMetaData = combinedData?.collectionMetadata;
  }

  // Subgraph may have isActive out-of-date due to multiple reasons so we need to update it from chain
  if (collectionMetaData)
    collectionMetaData.isActive = await collectionManager.collectionIsActive(
      collectibleAddress
    )


  try {
    let collectibleData;
    if (!collectionData || !collectionAdditionalData || !collectionMetaData || fetchLatest) {
      collectibleData = (await collectionManager.getCollectionInfo(collectibleAddress)); // refresh item
    }
    
    
    const entity = collectionData?.entity ?? collectibleData?._data?.entity;
    if (entity && entity?.toLowerCase() !== entityAddress?.toLowerCase()) return collectible;


    let totalSupply: any = undefined
    try {
      totalSupply = await loot8Collection.collectionCollectibleIds()
    } catch {}

    let collectibleName = collectionMetaData?.name ?? collectibleData?._name ?? "";
    let collectibleSymbol = collectionMetaData?.symbol ?? collectibleData?._symbol ?? "";
    collectible.dataURI = collectionMetaData?.dataURI ?? collectibleData?._dataURI ?? "";


    if (collectibleChainID !== NetworkId.ARBITRUM_ONE && collectibleChainID !== NetworkId.ETHEREUM_SEPOLIA) {
      collectibleName = await loot8Collection.name();
      collectibleSymbol = await loot8Collection.symbol();
      collectible.dataURI = await loot8Collection.contractURI();

    }

    if (!collectible?.dataURI && totalSupply === undefined) {
      if (
        (collectionMetaData?.collectionType ??
          collectibleData?._collectionType) === CollectionType.COLLECTION
      )
        try {
          const poapCollection = Loot8POAPCollection__factory.connect(
            collectibleAddress,
            getAnynetStaticProvider(collectibleChainID)
          )
          collectible.dataURI = await poapCollection.contractURI()
        } catch {}
    }

    let ipfsData;

    try {
      if (collectible.dataURI && collectible.dataURI !== "" && collectible.dataURI !== "ipfs://") {
        let response = await getIPFSData(collectible.dataURI);
        ipfsData = response && await response.json();
      }
    }
    catch (e) {
      LogToConsoleError(e);
    }

    if (ipfsData?.image) {
      collectible.imageProps.image = ipfsData && getIPFSLink(ipfsData?.image);
      collectible.imageProps.imageSize = ipfsData && ipfsData?.imageSize;
      collectible.imageProps.thumbnailImage = ipfsData && getIPFSLink(ipfsData?.thumbnailImage);
      collectible.imageProps.thumbnailImageSize = ipfsData && ipfsData?.thumbnailImageSize;
      collectible.imageProps.optimizedImage = ipfsData && getIPFSLink(ipfsData?.optimizedImage);
      collectible.imageProps.optimizedImageSize = ipfsData && ipfsData?.optimizedImageSize;
    }
    if (ipfsData?.video) {
      collectible.video = getIPFSLink(ipfsData.video);
      collectible.isVideoCollectible = true;
      collectible.imageProps.image = getIPFSLink(ipfsData.thumbnail);
    }

    if (ipfsData?.animation_url) {
      collectible.animationUrl = ipfsData.animation_url;
      let response = await getIPFSData(collectible?.animationUrl);
      collectible.animationUrlHtml = response && await response.text();
    }

    if (ipfsData?.discord || ipfsData?.telegram || ipfsData?.facebook || ipfsData?.twitter || ipfsData?.instagram || ipfsData?.tiktok) {
      collectible.socialLinks = {}
      if (ipfsData?.discord && ipfsData.discord !== "") {
        collectible.socialLinks.discord = ipfsData.discord;
      }
      if (ipfsData?.telegram && ipfsData.telegram !== "") {
        collectible.socialLinks.telegram = ipfsData.telegram;
      }
      if (ipfsData?.facebook && ipfsData.facebook !== "") {
        collectible.socialLinks.facebook = ipfsData.facebook;
      }
      if (ipfsData?.twitter && ipfsData.twitter !== "") {
        collectible.socialLinks.twitter = ipfsData.twitter;
      }
      if (ipfsData?.instagram && ipfsData.instagram !== "") {
        collectible.socialLinks.instagram = ipfsData.instagram;
      }
      if (ipfsData?.tiktok && ipfsData.tiktok !== "") {
        collectible.socialLinks.tiktok = ipfsData.tiktok;
      }
    }

    collectible.details = ipfsData?.description ?? "";
    collectible.subTitle = ipfsData?.subtitle ?? "";
    collectible.title = ipfsData?.name ?? "";
    collectible.timestamp = ipfsData?.timestamp ?? "";
    collectible.htmlTemplate = ipfsData?.htmlTemplate ?? "";


    collectible.address = collectibleAddress;
    collectible.chain = collectibleChainID;
    collectible.isActive = collectionMetaData?.isActive ?? collectibleData?._isActive;
    collectible.area = convertAreaData(
      collectionMetaData?.areaPoints ? JSON.parse(collectionMetaData?.areaPoints) : collectibleData._areaPoints,
      collectionMetaData?.areaRadius ? Number(collectionMetaData?.areaRadius) : collectibleData._areaRadius
    );
    collectible.isPremium=Boolean(collectibleData?._additionCollectionData?.isPremium)
    collectible.category=collectibleData?._additionCollectionData?.category

    collectible.entityAddress = collectionData?.entity ?? collectibleData?._data?.entity;
    let price = Number(collectionData?.price ?? collectibleData?._data?.price);
    collectible.price = Number(price / Math.pow(10, 18)).toFixed(2);
    collectible.start = Number(collectionData?.start ?? collectibleData?._data?.start) === 0 ? null : (new Date(Number(collectionData?.start ?? collectibleData?._data?.start) * 1000)); //blocktimestamp to timestamp
    collectible.end = Number(collectionData?.end ?? collectibleData?._data?.end) === 0 ? null : (new Date(Number(collectionData?.end ?? collectibleData?._data?.end) * 1000)); //blocktimestamp to timestamp
    // collectible.checkInNeeded = collectibleData?._data?.checkInNeeded;
    collectible.offerType = collectionData?.offerType ?? collectibleData?._data?.offerType;
    collectible.linkCollectible = collectionMetaData?.linkedCollections ? JSON.parse(collectionMetaData?.linkedCollections) : collectibleData?._linkedCollections;
    collectible.collectionType = collectionMetaData?.collectionType ?? collectibleData._collectionType;

    collectible.passportType = collectionAdditionalData?.mintModel ?? collectibleData?._additionCollectionData?.mintModel;

    collectible.totalSupply = Number(totalSupply) - 1;
    // collectible.tokensEarned = price > 0 ? Number(await tokenPriceCalculator.getTokensEligible(price)) / (1e18) : 0;
    collectible.maxMint = Number(collectionData?.maxMint ?? collectibleData?._data?.maxMint);
    collectible.maxPurchase = Number(collectionData?.maxPurchase ?? collectibleData?._data?.maxPurchase);
    collectible.maxBalance = Number(collectionAdditionalData?.maxBalance ?? collectibleData?._additionCollectionData?.maxBalance);
    collectible.mintWithLinked = collectionData?.mintWithLinked ?? collectibleData?._data?.mintWithLinked;
    collectible.mintWithLinkedOnly = collectionAdditionalData?.mintWithLinkedOnly ?? collectibleData?._additionCollectionData?.mintWithLinkedOnly;
    collectible.isCoupon = Number(collectionAdditionalData?.isCoupon ?? collectibleData?._additionCollectionData?.isCoupon) === 1;


    // marketPlace config
    if (collectible.collectionType === CollectionType.PASSPORT || collectible.collectionType === CollectionType.COLLECTION || collectible.collectionType === CollectionType.PREMIUM_ACCESS) {
      const marketPlaceConfig = await collectionHelper.marketplaceConfig(collectibleAddress); // refreshItem
      collectible.marketPlaceConfig = {
        allowMarketplaceOps: marketPlaceConfig.allowMarketplaceOps,
        privateTradeAllowed: marketPlaceConfig.privateTradeAllowed,
        publicTradeAllowed: marketPlaceConfig.publicTradeAllowed
      }

      collectible.socialMedia = (await getSocialMediaAccess(collectibleAddress, Number(collectibleChainID))); // refreshItem

    }

    if (collectible.collectionType === CollectionType.PASSPORT) {
      collectible.whitelistedCollections = await getAllWhitelistedCollectibleList({ networkID, provider, passportAddress: collectibleAddress, fetchLatest }); // refreshItem
    }

    collectible.name = collectibleName;
    collectible.symbol = collectibleSymbol;

    if (collectible.isCoupon) {
      const TierCollection = Loot8TieredCouponCollection__factory.connect(collectible.address, getAnynetStaticProvider(collectibleChainID));
      collectible.couponPoapAddress = await TierCollection.tierCollection();
      collectible.couponMaxTokens = Number(await TierCollection.maxTokens());
    }

    if (collectible.passportType === PassportType.SUBSCRIPTON) {
      const subscriptionConfig = (await subscriptionManager.subscriptionConfig(collectibleAddress));
      const floorPrice = subscriptionConfig.floorPrice;
      collectible.price = ethers.utils.formatUnits(floorPrice, 18);
      collectible.priceRate = Number(subscriptionConfig.priceRate) / 100;
      collectible.subscriptionSet = subscriptionConfig.subscriptionHasStarted;
    }

    await storeData(APP_STORAGE_GET_COLLECTIBLEDETAILS(collectibleAddress), collectible);

    return collectible;
  }
  catch (ex) {
    LogToConsoleError("getCollectibleDetails-" + collectibleAddress, ex.name, ex.message, ex.stack);
  }
  finally {
    return collectible;
  }
}

// * Get All Mints for All Expasses/Passports
// ? Get all mints for all Expasses/Passports owned by the entity
export const getPassportMintDetails = createAsyncThunk("passport/loadAllPassportMintDetails", async ({ wallet, networkID, provider, entity, isCache = true }: any, { getState }): Promise<any> => {
  const state = getState() as RootState;

  // * Get All Expasses/Passports
  const allPassports = state.Passports.AllEntityPassportDetails;

  // * Store Current Month Mints in this array
  let passportMintDetails = [];

  // * Determine Block Range for Current Month
  // Average Block Time is 0.32 seconds or ~4 Blocks per Second
  const avgBlockTime = 0.32;
  // Get Latest Block Number
  const latestBlockNumber = await provider.getBlockNumber();
  const latestBlockTimestamp = (await provider.getBlock(latestBlockNumber)).timestamp;

  // Get the current month from the latest block timestamp
  const currentMonth = new Date(latestBlockTimestamp * 1000).getMonth();

  // Calculate the timestamp for the start of the current month
  const startOfMonth = new Date(new Date().getFullYear(), currentMonth, 1).getTime() / 1000;

  // Determine the number of seconds elapsed from the start of the month to the latest block timestamp
  const secondsElapsed = latestBlockTimestamp - startOfMonth;

  // Calculate the number of blocks produced during this time
  const blocksProduced = Math.floor(secondsElapsed / avgBlockTime);

  // * Calculate the Block Number for the Start of this Month
  // ? This might not be 100% accurate but it saves ~1400 API calls to get the Blocks for each Log and compare timestamps
  const startBlockNumber = latestBlockNumber - blocksProduced;

  // * Get All Mints for Each Expasses/Passports
  // * Push them to passportMintDetails
  await Promise.all(
    allPassports.map(async (passport, index) => {
      // * Array for Storing Mints for Current Passport
      const mintsEventsForCurrentPassport: ICollectibleTransferEvent[] = [];

      let skip = 0;
      let pageSize = 100;
      let morePages = true;
      while (morePages) {
        const currentEvents = await fetchCollectibleTransfers(skip, pageSize, wallet?.address, passport?.address);
        if (currentEvents?.length > 0) {
          mintsEventsForCurrentPassport.push(...currentEvents);

          if (currentEvents.length < pageSize) {
            morePages = false;
          }
          // Increment skip for the next iteration
          skip += pageSize;
        } else {
          // Stop if no more data is returned
          morePages = false;
        }
      }

      // * Iterate over Mint Events for Current Passport
      // ? Check if the Log is within the Block Range for Current Month
      for (let i = 0; i < mintsEventsForCurrentPassport.length; i++) {
        const currentLog = mintsEventsForCurrentPassport[i];

        // ? Larger than the Start Block Number
        if (currentLog.blockNumber >= startBlockNumber) {
          // * Push the Log to Passport Mint Details
          passportMintDetails.push({ passport: currentLog.collection, tokenId: currentLog.tokenId, blockNumber: currentLog.blockNumber, transactionHash: currentLog.transactionHash, chainId: currentLog.chainId });
        }
      }

      LogToConsole("Passport mint details", passportMintDetails);
    })
  );

  return {
    AllPassportMintDetails: passportMintDetails,
  };
});

export const getSocialMediaAccess = async (collectionAddress: string, chainId: NetworkId) => {
  const feed = collectionAddress + ":" + chainId.toString();
  const response = await getCollectionFeedSettings(feed);
  if (response.status === 200) {
    const responseData = await response.json();
    if (responseData.messagingDisabled !== undefined) {
      return !responseData.messagingDisabled;
    }
  }
  else {
    LogToConsoleError("getSocialMediaAccess", response?.status?.toString(), response?.statusText, null);
    if (response.status === 500) {
      const res = await response.json();
      if (res && res.error) {
        LogToConsoleError(res.error);
      }
    }
  }
  return true;
}

export const toggleCollectionSociaMedialAccess = createAsyncThunk("passport/toggleCollectionSociaMedialAccess", async ({ wallet, collectionAddress, chainId, status, passport }: { wallet: Wallet, collectionAddress: string, chainId: NetworkId | "", status: boolean, passport?: string }): Promise<any> => {
  const feed = collectionAddress + ":" + chainId.toString();

  const response = await updateCollectionFeedSettings(feed, wallet, status, passport);
  let feedStatus = true;
  if (response.status === 200) {

    const feedResponse = await getCollectionFeedSettings(feed);
    if (feedResponse.status === 200) {
      const feedResponseData = await feedResponse.json();
      if (feedResponseData.messagingDisabled !== undefined) {
        feedStatus = !feedResponseData.messagingDisabled;
      }
    }

  }
  else {
    LogToConsoleError("toggleCollectionSociaMedialAccess", response?.status?.toString(), response?.statusText, null);
    if (response.status === 500) {
      const res = await response.json();
      if (res && res.error) {
        LogToConsoleError(res.error);
      }
    }
  }

  return {
    status: response.status,
    message: response.statusText,
    socialMedia: feedStatus
  }
});

export const loadAllPassporDetails = createAsyncThunk("passport/loadAllPassportDetails", async ({ networkID, provider, entityAddress, address, wallet, isCache = true, isSuperAdmin }: ICollectionBaseAsyncThunk, { dispatch, getState }): Promise<any> => {

  let allPassportDetails: ICollectibleDetail[] = [];

  const passportAddressLst = (await getAllCollectibleList({ networkID, provider, collectibleType: CollectionType.PASSPORT, entity: entityAddress }));

  if (passportAddressLst && passportAddressLst.length > 0) {
    await Promise.all(passportAddressLst.filter(collectibleAddress => collectibleAddress.source !== ZERO_ADDRESS).map(async (collectible, _index) => {
      let passportDetails: ICollectibleDetail = (await getCollectibleDetails({ networkID, provider, userAddress: wallet?.address, collectibleData: collectible, index: _index, entityAddress, isSuperAdmin }, { isCache,fetchLatest:true }));
      if (passportDetails && passportDetails?.name !== "") {
        dispatch(updatePassportData(passportDetails));
        allPassportDetails.push(passportDetails);
      }
    }));

  }
  if (allPassportDetails && allPassportDetails.length === 0) {
    dispatch(updatePassportData(null));
  }

  LogToConsole("all passports", allPassportDetails);

});


export const getIndividualWhitelistedCollections = createAsyncThunk("passport/getIndividualWhitelistedCollections", async ({ networkID, provider, isCache = true }: { networkID, provider, isCache: boolean }): Promise<any> => {


  let whitelistedCollections = await getAllWhitelistedCollectibleList({ networkID, provider, passportAddress: ZERO_ADDRESS });
  LogToConsole("IndividualWhitelistedCollections", whitelistedCollections);
  return {
    IndividualWhitelistedCollections: whitelistedCollections
  };
});

export const refreshPassportWhitelistedCollections = createAsyncThunk("passport/refreshPassportWhitelisted", async ({ networkID, provider }: { networkID, provider }, { getState }): Promise<any> => {

  const state = getState() as RootState;

  const allEntityPassportDetails = state.Passports.AllEntityPassportDetails;

  let refreshedPassportDetails = [];
  await Promise.all(allEntityPassportDetails.map(async (collectible, index) => {

    let whitelistedCollections = await getAllWhitelistedCollectibleList({ networkID, provider, passportAddress: collectible.address, fetchLatest: true });
    let refreshedCollectible = { ...collectible, whitelistedCollections: whitelistedCollections };
    refreshedPassportDetails.push(refreshedCollectible);
  }));

  return {
    AllEntityPassportDetails: refreshedPassportDetails
  };
});

export const UpdateCollectiblesdataURI = createAsyncThunk("Collections/UpdatepassportdataURI", async ({ networkID, provider, address, collectibleAddress, wallet, dataURI, chainID = networkID }: any, { dispatch }): Promise<any> => {
  let msg: IMessageMetaData = null;
  if (chainID === networkID) {
    const data = GetUpdateCollectionDataUriMessage(collectibleAddress, dataURI);
    if (address) {
      msg = {
        to: addresses[networkID].CollectionHelper,
        wallet: wallet,
        data: data,
        networkID: chainID,
        provider: getAnynetStaticProvider(chainID)
      }

    }
  }
  else {
    const data = GetUpdateCollectionDataUriOtherMessage(dataURI);
    if (address) {
      msg = {
        to: collectibleAddress,
        wallet: wallet,
        data: data,
        networkID: chainID,
        provider: getAnynetStaticProvider(chainID)
      }
    }
  }
  LogToConsole(msg);
  if (msg) {
    await dispatch(SendMetaTX(msg));
  }
});


export const CreateCouponCollection = createAsyncThunk("Collections/CreateCouponCollection",
  async ({ networkID, provider, address, EntityAddress, collectibleData, dataURI, wallet, _transferable = true, chainID = networkID, tierPoapCollection, maxTokens }:
    { networkID, provider, address, EntityAddress, collectibleData: ICollectibleDetail, dataURI, wallet, _transferable?: boolean, chainID?: NetworkId | "", tierPoapCollection: string, maxTokens: Number },
    { dispatch }): Promise<any> => {

    let name = collectibleData?.name ?? "";

    const data = GetCreateCouponCollectionMessage(
      EntityAddress,
      name,
      collectibleData?.symbol !== "" ? collectibleData?.symbol : name,
      dataURI !== "" ? dataURI : "",
      _transferable,
      addresses[networkID].CollectionManager,
      addresses[chainID].CollectionHelper,
      addresses[chainID].LayerZeroEndPoint,
      tierPoapCollection,
      maxTokens
    );

    if (address) {
      let msg: IMessageMetaData = {
        to: addresses[chainID].TieredCouponCollectionFactory,
        wallet: wallet,
        data: data,
        networkID: chainID,
        provider: getAnynetStaticProvider(chainID)
      }
      LogToConsole(msg);

      let res = await dispatch(SendMetaTX(msg));
      if (res && res.payload?.eventLogs) {
        const createdCollection = GetCouponCollectionCreatedLogData(res.payload?.eventLogs, chainID);
        if (createdCollection === ZERO_ADDRESS) {
          LogToConsole('Coupon Creation: transaction failed...')
        }
        return createdCollection;
      }
    }
    return ZERO_ADDRESS;
  });

export const CreateCollection = createAsyncThunk("Collections/CreateCollection", async ({ networkID, provider, address, EntityAddress, collectibleData, dataURI, wallet, _transferable = true, chainID = networkID }: { networkID, provider, address, EntityAddress, collectibleData: ICollectibleDetail | any, dataURI, wallet, _transferable?: boolean, chainID?: NetworkId | "" }, { dispatch }): Promise<any> => {
  let name = collectibleData?.name?.trim() ?? "";
  const manager = addresses[chainID].CollectionManager;

  const data = GetCreateCollectionMessage(
    EntityAddress,
    name,
    collectibleData?.symbol !== "" ? collectibleData?.symbol : name,
    dataURI !== "" ? dataURI : "",
    _transferable,
    manager,
    addresses[chainID].CollectionHelper,
    addresses[networkID].SubscriptionManager,
    addresses[chainID].LayerZeroEndPoint,
  );

  

  if (address) {
    let msg: IMessageMetaData = {
      to: addresses[chainID].CollectionFactory,
      wallet: wallet,
      data: data,
      networkID: chainID,
      provider: getAnynetStaticProvider(chainID)
    }
    LogToConsole('collectionCreation', msg);

    let res = await dispatch(SendMetaTX(msg));
    
    if (res && res.payload?.eventLogs) {
      const createdCollection = GetCollectionCreatedLogData(res.payload?.eventLogs);
      
      if (createdCollection === ZERO_ADDRESS) {
        LogToConsole('Collection Creation New: transaction failed...sss')
      }
      return createdCollection;
    }
  }
  return ZERO_ADDRESS;
});

export const AddCollection = createAsyncThunk("Collections/AddCollection", async ({ networkID, provider, address, EntityAddress,isPremium, category,collectibleAddress, collectibleData, wallet, chainID = networkID} 
  : AddCollectionAsyncThunk, { dispatch }): Promise<any> => {
  let CollectibleType = collectibleData?.collectionType;
  let isCollectible =
    CollectibleType === CollectionType.COLLECTION &&
    (collectibleData.offerType == OfferType.NOTANYOFFER ||
      collectibleData.offerType == OfferType.REGULAR)   
  
  let privateMessageCap = collectibleData?.privateMessageCap ?? 1;
  let startDate = collectibleData?.start ? Math.floor(new Date(collectibleData?.start).getTime() / 1000) : 0;
  let endDate = collectibleData?.end ? Math.floor(new Date(collectibleData?.end).getTime() / 1000) : 0;
  let area = [[collectibleData?.area.latitude, collectibleData?.area.longitude], Number(collectibleData?.area.radius)]
  const floorPrice = ethers.utils.parseUnits(collectibleData?.price?.toString(), 18).toString();
  // collection data : [Entity, mintWithLinked, price, maxPurchase, start, end, checkInNeeded, maxMint, OfferType, passport, minRewardBalance, minVisits, minFriendVisits,privateMessageCap, _gap[20]]
  let CollectionData = [  
                          EntityAddress, isCollectible || (collectibleData.mintWithLinked ?? false), floorPrice, collectibleData?.maxPurchase ,
                          startDate, endDate,
                          false, collectibleData?.maxMint, collectibleData?.offerType, ZERO_ADDRESS, 0, 0, 0,privateMessageCap,
                          getZeroArray(19)
                        ]
  let additionalCollectionData = [ collectibleData?.maxBalance , collectibleData?.mintWithLinkedOnly  ?? false , Number(collectibleData?.isCoupon), collectibleData.passportType ?? 0,isPremium ? isPremium : false,category, getZeroArray(17) ]
  const _tradability = collectibleData?.marketPlaceConfig?.publicTradeAllowed ?? false;
  const data = GetAddCollectionMessage(collectibleAddress, chainID, CollectibleType, CollectionData, additionalCollectionData, area, _tradability)

  if (address) {
    let msg: IMessageMetaData = {
      to: addresses[networkID].CollectionManager,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider
    }
    LogToConsole(msg);
    let addCollectionMeta = await dispatch(SendMetaTX(msg));
    
  }
});

export const AddFreeCollection = createAsyncThunk("Collections/AddCollection", async ({ networkID, provider, address, EntityAddress, isPremium, category, collectibleAddress, collectibleData, wallet, chainID = networkID }
  : AddFreeExPassAsyncThunk, { dispatch }): Promise<any> => {
  let CollectibleType = collectibleData?.collectionType;
  let privateMessageCap = collectibleData?.privateMessageCap ?? 1;
  let startDate = collectibleData?.start ? Math.floor(new Date(collectibleData?.start).getTime() / 1000) : 0;
  let endDate = collectibleData?.end ? Math.floor(new Date(collectibleData?.end).getTime() / 1000) : 0;
  let area = [[collectibleData?.area?.latitude, collectibleData?.area?.longitude], Number(collectibleData?.area?.radius)]
  const floorPrice = ethers.utils.parseUnits(0?.toString(), 18).toString();
  // collection data : [Entity, mintWithLinked, price, maxPurchase, start, end, checkInNeeded, maxMint, OfferType, passport, minRewardBalance, minVisits, minFriendVisits, _gap[20]]
  let CollectionData = [
    EntityAddress, collectibleData.mintWithLinked ?? false, floorPrice, collectibleData?.maxPurchase,
    startDate, endDate,
    false, collectibleData?.maxMint, collectibleData?.offerType, ZERO_ADDRESS, 0, 0, 0, privateMessageCap,
    getZeroArray(19)
  ]
  let additionalCollectionData = [collectibleData?.maxBalance, collectibleData?.mintWithLinkedOnly ?? false, Number(collectibleData?.isCoupon), collectibleData.passportType ?? 0, false, category, getZeroArray(17)]
  const _tradability = collectibleData?.marketPlaceConfig?.publicTradeAllowed ?? false;
  
  const data = GetAddCollectionMessage(collectibleAddress, chainID, CollectibleType, CollectionData, additionalCollectionData, area, _tradability)
  
  if (address) {
    let msg: IMessageMetaData = {
      to: addresses[networkID].CollectionManager,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider
    }
    LogToConsole(msg);
    await dispatch(SendMetaTX(msg));
  }
});

export const UpdateCollectionData = createAsyncThunk("Collections/UpdateCollectionData", async ({ networkID, provider, address, EntityAddress,isPremium, collectibleAddress,category, collectibleData, wallet }: AddCollectionAsyncThunk, { dispatch }): Promise<any> => {
  let startDate = collectibleData?.start ? Math.floor(new Date(collectibleData?.start).getTime() / 1000) : 0;
  let endDate = collectibleData?.end ? Math.floor(new Date(collectibleData?.end).getTime() / 1000) : 0;
  const floorPrice = ethers.utils.parseUnits(collectibleData?.price?.toString(), 18).toString();
  let privateMessageCap = collectibleData?.privateMessageCap ?? 1;

  // collection data : [Entity, mintWithLinked, price, maxPurchase, start, end, checkInNeeded, maxMint, OfferType, passport, minRewardBalance, minVisits, minFriendVisits, _gap[20]]
  let additionalCollectionData = [  collectibleData?.maxBalance , collectibleData?.mintWithLinkedOnly ?? false , Number(collectibleData?.isCoupon) , collectibleData.passportType ?? 0,isPremium ?? false,category , getZeroArray(17) ]
  let CollectionData =  [  
                          EntityAddress, collectibleData.mintWithLinked ?? false, floorPrice, collectibleData?.maxPurchase ,
                          startDate, endDate, 
                          false, collectibleData?.maxMint, collectibleData?.offerType, ZERO_ADDRESS, 0, 0, 0, privateMessageCap,
                          getZeroArray(19)
                        ]
  let area = [[collectibleData?.area.latitude, collectibleData?.area.longitude ] , Number(collectibleData?.area.radius) ]
let data = null
  try {
   data = GetUpdateCollectionDataMessage(collectibleAddress, CollectionData, additionalCollectionData, area)
  } catch (error) {
    console.log({error});
  }


  if (address) {
    let msg: IMessageMetaData = {
      to: addresses[networkID].CollectionManager,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider
    }
    LogToConsole(msg);
    const res = await dispatch(SendMetaTX(msg));
    if (res && res?.payload?.eventLogs) {
      const updatedCollection = GetCollectionUpdatedLogData(res?.payload?.eventLogs)
      if (updatedCollection === ZERO_ADDRESS) {
        LogToConsole('Collection Updation: transaction failed...')
      }
      return updatedCollection;
    }
  }
});

export const linkCollection = createAsyncThunk("Collections/linkCollection", async ({ networkID, provider, address, collectibleAddressA, linkingAddresses, wallet }: any, { dispatch }): Promise<any> => {

  const data = GetLinkCollectionMessage(collectibleAddressA, linkingAddresses);

  if (address) {
    let msg: IMessageMetaData = {
      to: addresses[networkID].CollectionHelper,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider
    }
    LogToConsole(msg);
    await dispatch(SendMetaTX(msg));
  }
});

export const delinkCollection = createAsyncThunk("Collections/delinkCollection", async ({ networkID, provider, address, collectibleAddressA, collectibleAddressB, wallet }: any, { dispatch }): Promise<any> => {

  const data = GetDelinkCollectionMessage(collectibleAddressA, collectibleAddressB);

  if (address) {
    let msg: IMessageMetaData = {
      to: addresses[networkID].CollectionHelper,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider
    }
    LogToConsole(msg);
    await dispatch(SendMetaTX(msg));
  }
});

export const retireCollection = createAsyncThunk("Collections/retireCollection", async ({ networkID, provider, address, collectibleAddress, wallet }: any, { dispatch }): Promise<any> => {

  const data = GetRetireCollectionMessage(collectibleAddress);

  if (address) {
    let msg: IMessageMetaData = {
      to: addresses[networkID].CollectionManager,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider
    }
    LogToConsole(msg);
    await dispatch(SendMetaTX(msg));
  }
});

export const SetMarketPlaceTradability = createAsyncThunk("Collections/MarketPlaceTradability ", async ({ networkID, provider, address, wallet, collectibleAddress, marketPlaceConfig }: any, { dispatch }): Promise<any> => {
  const data = GetMarketPlaceTradablityMessage(collectibleAddress, marketPlaceConfig?.privateTradeAllowed, marketPlaceConfig?.publicTradeAllowed);

  if (data) {
    let msg: IMessageMetaData = {
      to: addresses[networkID].CollectionHelper,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider
    }
    LogToConsole(msg);
    await dispatch(SendMetaTX(msg));
  }

});

export const AddSubscriptionPassport = createAsyncThunk("Collections/SubscriptionPassport", async ({ networkID, provider, address, collectibleAddress, floorPrice, wallet, priceRate }: any, { dispatch }): Promise<any> => {
  // const feePercent = ethers.utils.parseUnits("0.10", 4); // 10% Fees
  const data = GetSubscriptionPassportDataMessage(collectibleAddress, floorPrice, priceRate ? Number(priceRate) * 100 : 1000, true, true);
  // _passport, _peopleFeePercent, _platformFeePercent, _peopleFeeReceiver, _floorPrice, _priceRate, _tradingEnabled, _startSubscription
  if (data) {
    let msg: IMessageMetaData = {
      to: addresses[networkID].SubscriptionManager,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider
    }
    LogToConsole(msg);
    
    let res = await dispatch(SendMetaTX(msg));
    
    if (res && res.payload?.eventLogs) {
      const createdCollection = GetAddSubscriptionPassportLogData(res.payload?.eventLogs);
    

      if (createdCollection === ZERO_ADDRESS) {
        LogToConsole('Collection Creation: transaction failed...')
      }
      return createdCollection;
    }
  }
  return ZERO_ADDRESS;
});

const initialState: IPassportSliceData = {

  AllEntityPassportDetails: null,
  AllPassportMintDetails: null,
  IndividualWhitelistedCollections: null,
  loading: false,
  mintDetailsLoading: false
};

const PassportSlice = createSlice({
  name: "PassportDetails",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
    updatePassportSocialData(state, action) {
      let passportData = state.AllEntityPassportDetails?.find(obj => obj.address?.toLowerCase() === action.payload.address?.toLowerCase());
      passportData.socialMedia = action.payload.socialMedia;
    },
    removeTransferedPassport(state, action) {
      let addressOfPasswordToBeRemoved = action?.payload?.address;
      const allPassports = state?.AllEntityPassportDetails ?? [];
      state.AllEntityPassportDetails = allPassports?.filter(i => i?.address?.toLowerCase() != addressOfPasswordToBeRemoved?.toLowerCase());
      //  = filteredPassports?.sort((a,b) => Number(b.index) - Number(a.index));
    },
    updatePassportData(state, action) {
      let allPassportData = state.AllEntityPassportDetails ?? [];

      if (action.payload) {
        let collectionData = allPassportData.find(obj => obj.address?.toLowerCase() === action.payload.address?.toLowerCase());
        if (collectionData) {
          allPassportData = allPassportData.filter(obj => obj.address?.toLowerCase() !== collectionData.address?.toLowerCase());
          collectionData = { ...collectionData, ...action.payload };
        }
        else {
          collectionData = action.payload;
        }
        allPassportData.push(collectionData);
      }
      state.AllEntityPassportDetails = allPassportData.sort((a, b) => Number(b.index) - Number(a.index));
    },
    setPassportLoading(state, action) {
      state.loading = action.payload;
    },
    clearAllPassportData(state) {
      state.AllEntityPassportDetails = [];
      state.AllPassportMintDetails = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadAllPassporDetails.pending, (state, action) => {
        // state.loading = true;
      })
      .addCase(loadAllPassporDetails.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(loadAllPassporDetails.rejected, (state: { loading: boolean; }, { error }: any) => {
        state.loading = false;
        LogToConsoleError("loadAllPassporDetails", error.name, error.message, error.stack);
      })
      .addCase(getPassportMintDetails.pending, (state, action) => {
        state.mintDetailsLoading = true;
      })
      .addCase(getPassportMintDetails.fulfilled, (state, action) => {
        state.AllPassportMintDetails = action.payload.AllPassportMintDetails;
        state.mintDetailsLoading = false;
      })
      .addCase(getPassportMintDetails.rejected, (state, { error }: any) => {
        state.mintDetailsLoading = false;
        LogToConsoleError("getPassportMintDetails", error.name, error.message, error.stack);
      })
      .addCase(refreshPassportWhitelistedCollections.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(refreshPassportWhitelistedCollections.fulfilled, (state, action) => {
        state.AllEntityPassportDetails = action.payload.AllEntityPassportDetails;
        state.loading = false;
      })
      .addCase(refreshPassportWhitelistedCollections.rejected, (state, { error }: any) => {
        state.loading = false;
        LogToConsoleError("refreshPassportWhitelistedCollections", error.name, error.message, error.stack);
      })
      .addCase(getIndividualWhitelistedCollections.fulfilled, (state, action) => {
        state.IndividualWhitelistedCollections = action.payload.IndividualWhitelistedCollections;
      })
      .addCase(getIndividualWhitelistedCollections.rejected, (state, { error }: any) => {
        LogToConsoleError("getIndividualWhitelistedCollections", error.name, error.message, error.stack);
      })
  },
});

export const PassportSliceReducer = PassportSlice.reducer;

const baseInfo = (state: RootState) => state.Passports;

export const { fetchAppSuccess, updatePassportSocialData, removeTransferedPassport, setPassportLoading, updatePassportData, clearAllPassportData } = PassportSlice.actions;

export const getPassportState = createSelector(baseInfo, PassportSlice => PassportSlice);