// TODO: add passport slice data
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { addresses, APP_STORAGE_GET_COLLECTIBLEDETAILS, APP_STORAGE_GET_EXTERNAL_COLLECTIBLEDETAILS, APP_STORAGE_PHYGITAL_LIST, getAnynetStaticProvider, getGenesisBlockNumber, getStaticLogsProvider, getSubgraphConfig, isProduction, isStaging, NetworkId, POAP_LAST_FETCH_BLOCK, ZERO_ADDRESS } from "../constants";

import { getIPFSData, getIPFSLink } from "../helpers/ipfs";
import { RootState } from "../store";
import { ExternalCollectionManager__factory, Loot8POAPCollection__factory, Loot8UniformCollection__factory, POAPCollectionFactory__factory } from "../typechain/factories";
import { SendMetaTX } from "./AppSlice";
import { setAll } from "./helpers";
import { IExternalCollectibleDetail } from "../interfaces/IExternalCollection.interface";
import { GetDelistCollectionMessage, GetPOAPCollectionCreatedLogData, GetPOAPCreateCollectionMessage, GetWhitelistCollectionMessage } from "../helpers/MsgHelper";
import { IMessageMetaData } from "./interfaces";
import { IPOAPCollectibleDetail } from "../interfaces/IPOAPCollection.interface";
import { IExternalCollectionManager } from "../typechain/ExternalCollectionManager";
import { getSocialMediaAccess } from "./PassportSlice";
import { LogToConsole, LogToConsoleError } from "../helpers/Logger";
// import { fetchPOAPCollections } from "../helpers/GraphQLHelper";
import { fetchPOAPCollections, fetchWhitelistedCollections } from "../helpers/GraphQLHelperSubgraph";
import { getData, storeData } from "../helpers/AppStorage";
import { CollectionType } from "../enums/collection.enum";
import { OfferType } from "../enums/offers.enum";


export const getAllWhitelistedCollectibleList = async ({ networkID, provider, passportAddress, fetchLatest = false }:  { networkID, provider, passportAddress, fetchLatest?: boolean }): Promise<IExternalCollectionManager.ContractDetailsStructOutput[]> => {

  let whitelistedCollections = null;
  try{
    let subgraphConfig: any;
    try {
        subgraphConfig = await getSubgraphConfig();
    } catch (err) {
        LogToConsoleError("getWhitelistedCollectionsForPassport: Error while reading app config");
    }

    if (subgraphConfig && subgraphConfig.modules && subgraphConfig.modules.whitelistedCollections && !fetchLatest) {
      whitelistedCollections = await fetchWhitelistedCollections(passportAddress);
    }
  }
  catch(err){
    LogToConsoleError("getWhitelistedCollectionsForPassport", err.name, err.message, err.stack);
  }
  //if indexer is disable or any error occurred while fetching data from indexer then fetch data from contract
  if(whitelistedCollections === null) {
    const ExternalCollectionManager = ExternalCollectionManager__factory.connect(addresses[networkID].ExternalCollectionManager, provider);
    const whitelisted = await ExternalCollectionManager.getWhitelistedCollectionsForPassport(passportAddress);
    whitelistedCollections = whitelisted.flatMap(({source, chainId}) => ({source, chainId: Number(chainId), passport: passportAddress}))
  }
  return whitelistedCollections;
}

export const getExternalCollectibleDetails =async ({ collectibleData, index, isCache}) : Promise<any> => {
    let collectible : IExternalCollectibleDetail = null;
    let collectibleAddress = collectibleData.source;
    let collectibleChainID = Number(collectibleData.chainId);

    collectible = isCache ? await getData(APP_STORAGE_GET_EXTERNAL_COLLECTIBLEDETAILS(collectibleAddress)) : null;

    if(collectible && isCache) {
      return collectible;
    }

    try {
      const loot8Collection = Loot8UniformCollection__factory.connect(collectibleAddress, getAnynetStaticProvider(collectibleChainID));

        let collectibleName = await loot8Collection.name();
        let collectibleSymbol = await loot8Collection.symbol();

        collectible = {
            chain: collectibleChainID,
            index: index,
            name: collectibleName,
            address: collectibleAddress,
            symbol: collectibleSymbol,
            linkPassports: [],
        }

        await storeData(APP_STORAGE_GET_EXTERNAL_COLLECTIBLEDETAILS(collectibleAddress), collectible);

        return collectible;
    }
    catch(ex) {
        LogToConsoleError("getExternalCollectibleDetails-" + collectibleAddress, ex.name, ex.message, ex.stack);
    }
  return collectible;
}
 

export const loadExternalCollectibleDetails = createAsyncThunk("ExternalCollections/loadExternalCollectibleDetails", async ({ networkID, provider, isCache = true }: any, { getState }): Promise<any> => {
  let allExternalCollectibleList = [];
  let allExternalCollectibleDetails = [];

  const state = getState() as RootState;
  const allPassports = state.Passports.AllEntityPassportDetails;
  
    if( allPassports && allPassports.length > 0 ) {
        await Promise.all( allPassports.filter(passport => passport.address !== ZERO_ADDRESS ).map(async (passport, _index) => {
            let collectibleAddressList = passport?.whitelistedCollections;

            if(collectibleAddressList && collectibleAddressList.length > 0 ){
                allExternalCollectibleList = [...allExternalCollectibleList, ...collectibleAddressList];
            }
        }))
    }

  if( allExternalCollectibleList && allExternalCollectibleList.length > 0 ) {
    let uniqueCollectibles: any = [...new Set(allExternalCollectibleList.map(item => item.source ))];

    await Promise.all( uniqueCollectibles.filter(collectibleAddress => collectibleAddress !== ZERO_ADDRESS ).map(async (collectibleAddress, index) => {
        let chainId = allExternalCollectibleList.find(obj => obj.source === collectibleAddress).chainId;
        let collectibleDetails : IExternalCollectibleDetail = (await getExternalCollectibleDetails({ collectibleData : { source: collectibleAddress, chainId: chainId }, index, isCache}));
        if(collectibleDetails) {
            collectibleDetails.linkPassports = allExternalCollectibleList.filter(obj => obj.source?.toLowerCase() === collectibleAddress?.toLowerCase()).map(obj => obj.passport);
            allExternalCollectibleDetails.push(collectibleDetails);
        }
    }));
  }

  LogToConsole("all external collectibles", allExternalCollectibleDetails);

  return {
    AllExternalCollectibleDetails: allExternalCollectibleDetails
 };

});


export const getAllPOAPCollectibleList = async ({ networkID, provider, entityAddress, isCache }:  { networkID, provider, entityAddress, isCache } ): Promise<any[]> => {
  
  const cachedCollectibleList = isCache ? await getData(APP_STORAGE_PHYGITAL_LIST(entityAddress, networkID)) : [];

  const poapCollectionFactory = POAPCollectionFactory__factory.connect( addresses[networkID].POAPCollectionFactory, getStaticLogsProvider(networkID));

  // TODO: Replace this with Indexer Query
  // * Set it to 4000 blocks behind
  // ? This will atleast get the Phygitals created within the
  // ? last 4000 blocks or 13-17 minutes
  // let fromBlockNumber
  // try {
  //   const latestBlockNumber = await provider.getBlockNumber();
  //   fromBlockNumber = latestBlockNumber - 4000;
  // } catch (error) {
  //   LogToConsoleError(`Error fetching Latest Block for ${poapCollectionFactory.address} ${networkID} ${error}`);
  //   return
  // }


  // * Interface for the POAPCollectionFactory CreatedCollection Event
  let allPOAPCollectibleList : {address: string, index: number, chainId: NetworkId, timeStamp: Date}[] = cachedCollectibleList ?? [];
  
  // * Query Filter for the CreatedCollection Event
  // let queryFilter =  poapCollectionFactory.filters.CreatedCollection(null, null);
  
  // let createLogEvents


  // try {
  //   // * Fetch the CreatedCollection Events
  //   createLogEvents = await poapCollectionFactory.queryFilter(queryFilter, fromBlockNumber, null);
  // } catch (error) {
  //   LogToConsoleError(`Error fetching Logs for ${poapCollectionFactory.address} ${networkID} from block number ${fromBlockNumber} ${error}`);
  //   return
  // }
 

  // * Loop through the CreatedCollection Events
  // ? Extract the required data and add it to the allPOAPCollectibleList
  
  let skip = 0
  const take = 100
  let hasMoreData = true

  let allPOAPCollectibles = []

  while (hasMoreData) {
    const response = await fetchPOAPCollections(skip, take, entityAddress)
    allPOAPCollectibles = [...allPOAPCollectibles, ...response]
    hasMoreData = response.length === take
    skip += take
  }
  
  if(allPOAPCollectibles && allPOAPCollectibles?.length){
    allPOAPCollectibles?.forEach((i,index)=>{
      let timeStamp = new Date( i.blockNumber * 1000 );
      allPOAPCollectibleList.push({ address : i.collection, index , chainId: networkID, timeStamp });
    })
    await storeData(APP_STORAGE_PHYGITAL_LIST(entityAddress, networkID), allPOAPCollectibleList);

  }
  return allPOAPCollectibleList;
  // await Promise.all(createLogEvents.map(async (log, index) => {
  //   let event = log.args;
  //   if(event._entity?.toLowerCase() === entityAddress?.toLowerCase()){
  //     let block = await log.getBlock();
      
  //     console.log({event:event._collection});
  //     allPOAPCollectibleList.push({ address : event._collection, index: index , chainId: networkID, timeStamp: timeStamp });

  //   }
  // }));
}

// export const getPOAPTokenDetails = async ({ POAPCollection, provider }: { POAPCollection: Loot8POAPCollection, provider } ): Promise<IPOAPTokenDetails[]> => {

//   let currentBlockNumber = await provider.getBlockNumber(); 
//   let queryFilter = POAPCollection.filters.Transfer(ZERO_ADDRESS, null, null);
//   const createdBlock = (await POAPCollection.queryFilter(POAPCollection.filters.OwnershipTransferred(ZERO_ADDRESS, null)))[0].blockNumber ;

//   let mintLogEvents = [];
//   while(mintLogEvents.length === 0 && Number(currentBlockNumber) > Number(createdBlock)){
//     let events = await POAPCollection.queryFilter(queryFilter, currentBlockNumber-10000, currentBlockNumber);
//     mintLogEvents = mintLogEvents.concat(events);
//     currentBlockNumber-=100000; 
//   }

//   let tokenURIs = []
//   let collectibleTokenDetails : IPOAPTokenDetails[] = [];

//   await Promise.all (mintLogEvents.map( async (_log : TransferEvent) => {
//     let tokenID =  Number(_log.args.tokenId);

//     let tokenURI = await POAPCollection.tokenURIs(tokenID);
//     if(tokenURI) {
//       tokenURIs.push({tokenID, tokenURI});
//     }
//   }));

//   const filteredTokenURIs = tokenURIs.filter((value, index, self) =>  self.findIndex(v => v.tokenURI === value.tokenURI ) === index );

//   await Promise.all(filteredTokenURIs.map(async obj => {

//     let collectibleToken : IPOAPTokenDetails = {
//       tokenId: null,
//       tokenURI: "",
//       name: "",
//       details: "",
//       image: "",
//       totalToken: 0
//     } 
//     collectibleToken.tokenId = obj.tokenID;
//     collectibleToken.tokenURI = obj.tokenURI;
//     let ipfsData;

//     try {
//       let response = await getIPFSData(collectibleToken.tokenURI);
//       ipfsData = response && await response.json();
//     }
//     catch(e){
//       LogToConsoleError(e);
//     }
//     collectibleToken.image = ipfsData && ipfsData.image && getIPFSLink(ipfsData?.image);
//     collectibleToken.name = ipfsData?.name;
//     collectibleToken.details = ipfsData?.description;
//     collectibleToken.totalToken = tokenURIs.filter(token => token.tokenURI ===  collectibleToken.tokenURI).length;

//     collectibleTokenDetails.push(collectibleToken);

//   }));

//   return collectibleTokenDetails;
// }


export const getPOAPCollectibleDetails =async ({ networkID, provider, collectibleData }, isCache = true) : Promise<IPOAPCollectibleDetail> => {
  let collectible : IPOAPCollectibleDetail =  null;
  let collectibleChainID = Number(networkID);
  let collectibleAddress = collectibleData.address

  collectible = isCache ? await getData(APP_STORAGE_GET_COLLECTIBLEDETAILS(collectibleAddress)) : null;

  if(collectible && isCache) return collectible;

  const POAPCollection = Loot8POAPCollection__factory.connect(collectibleAddress, provider);

  collectible = {
    chain: collectibleChainID,
    index: collectibleData?.index,
    name: "",
    area: {
      latitude: "",
      longitude: "",
      radius: 0
    },
    privateMessageCap:0,
    address: collectibleAddress,
    offerType:OfferType.NOTANYOFFER,
    maxMint: 0,
    maxPurchase: 0,
    isCoupon:false,
    isPremium:false,
    maxBalance: 0,
    mintWithLinked: false,
    mintWithLinkedOnly: false,
    symbol: "",
    details: "",
    dataURI: "",
    imageProps: {
      image: "",
      imageSize: 0
    },    autoStartMint: 1,
    marketPlaceConfig: {
      allowMarketplaceOps: false,
      privateTradeAllowed: false,
      publicTradeAllowed: false,
    },
    price: '0.00',
  collectionType: CollectionType.COLLECTION,
    priceRate:0,
    start: null,
    end: null,
    linkCollectible: [],
    isActive: false,
    timeStamp: null,
    socialMedia: true,
    isVideoCollectible: false,
    video: null
  }
  try {
      let collectibleName = await POAPCollection.name();
      let collectibleSymbol = await POAPCollection.symbol();

      collectible.name = collectibleName;
      collectible.symbol = collectibleSymbol;
      collectible.timeStamp = collectibleData.timeStamp;


      collectible.dataURI = await POAPCollection.contractURI();

      let ipfsData;
      try {
        let response = await getIPFSData(collectible.dataURI);
        ipfsData = response && await response.json();
      }
      catch(e){
        LogToConsoleError(e);
      }

      
      if (ipfsData?.image) {
        collectible.imageProps.image = ipfsData && getIPFSLink(ipfsData.image);
        collectible.imageProps.imageSize = ipfsData && ipfsData?.imageSize;
        collectible.imageProps.thumbnailImage = ipfsData && getIPFSLink(ipfsData?.thumbnailImage);
        collectible.imageProps.thumbnailImageSize = ipfsData && ipfsData?.thumbnailImageSize;
        collectible.imageProps.optimizedImage = ipfsData && getIPFSLink(ipfsData?.optimizedImage);
        collectible.imageProps.optimizedImageSize = ipfsData && ipfsData?.optimizedImageSize;
      }
      if (ipfsData?.video) {
        collectible.video = getIPFSLink(ipfsData.video);
        collectible.isVideoCollectible = true;
        collectible.imageProps.image = getIPFSLink(ipfsData.thumbnail);
      }
      collectible.details = ipfsData?.description;

      collectible.autoStartMint = Number(await POAPCollection.autoTokenIdStart());
      collectible.isActive = !(await POAPCollection.disabled());
      collectible.socialMedia = (await getSocialMediaAccess(collectibleData.address, Number(isProduction ? NetworkId.ARBITRUM_ONE : isStaging ? NetworkId.ARBITRUM_NOVA_FORK : NetworkId.ETHEREUM_SEPOLIA)));

      // try {
      //   collectible.tokenDetails = await getPOAPTokenDetails({ POAPCollection, provider });
      // }
      // catch(e) {
      //   LogToConsoleError("get Token details", e);
      // }
      await storeData(APP_STORAGE_GET_COLLECTIBLEDETAILS(collectibleAddress), collectible);

      return collectible;
  }
  catch(ex) {
      LogToConsoleError("getPOAPCollectibleDetails-" + collectibleAddress, ex.name, ex.message, ex.stack);
  }
  return collectible;
}

export const loadAllPOAPCollectibleDetails = createAsyncThunk("POAPCollections/loadAllPOAPCollectibleDetails", async ({ networkID, provider , entityAddress, isCache = true } : { networkID, provider , entityAddress, isCache? }, { dispatch, getState }): Promise<any> => {

  let allPOAPCollectibleList = await getAllPOAPCollectibleList({ networkID, provider, entityAddress, isCache });
  const polygonNetworkID = isProduction? NetworkId.POLYGON_MAINNET : NetworkId.POLYGON_MUMBAI_TESTNET
  let allPOAPPolygon = await getAllPOAPCollectibleList({ networkID: polygonNetworkID , provider: getAnynetStaticProvider(polygonNetworkID), entityAddress, isCache});
  let allArbitrum = isProduction ?  await getAllPOAPCollectibleList({ networkID: isProduction ?  NetworkId.ARBITRUM_ONE : isStaging ? NetworkId.ARBITRUM_NOVA_FORK : NetworkId.ETHEREUM_SEPOLIA , provider: getAnynetStaticProvider(isProduction ?  NetworkId.ARBITRUM_ONE : isStaging ? NetworkId.ARBITRUM_NOVA_FORK : NetworkId.ETHEREUM_SEPOLIA ), entityAddress, isCache}): [];

  allPOAPCollectibleList = allPOAPCollectibleList.concat(allPOAPPolygon).concat(allArbitrum);

  let allPOAPCollectibleDetails: IPOAPCollectibleDetail[] =  [];

  


  const state = getState() as RootState;
  const allEntityPassportDetails = state.Passports.AllEntityPassportDetails;
  allPOAPCollectibleList = allPOAPCollectibleList
      ?.sort((a, b) => Number(new Date(a.timeStamp).getTime()) - Number(new Date(b.timeStamp).getTime()))
      ?.map((obj, newIndex) => ({ ...obj, index: newIndex + 1 }));

  await Promise.all(allPOAPCollectibleList.filter(collectibleAddress => collectibleAddress.address !== ZERO_ADDRESS ).map(async (collectibleData, index) => { 

    let collectibleDetails = await getPOAPCollectibleDetails({ networkID : isProduction ? NetworkId.ARBITRUM_ONE : isStaging ? NetworkId.ARBITRUM_NOVA_FORK :  NetworkId.ETHEREUM_SEPOLIA, provider: getAnynetStaticProvider(isProduction ? NetworkId.ARBITRUM_ONE : isStaging ? NetworkId.ARBITRUM_NOVA_FORK : NetworkId.ETHEREUM_SEPOLIA), collectibleData: collectibleData  }, isCache);
    collectibleDetails.index = Number(index) + 1;
    allEntityPassportDetails?.forEach((passport) => {
      if(passport.whitelistedCollections.filter(collectible => collectible.source?.toLowerCase() === collectibleData.address?.toLowerCase()).length > 0) {
        collectibleDetails.linkCollectible.push(passport.address);
      }
    });

    if(collectibleDetails.name !== "") {
      dispatch(updatePhygitalData(collectibleDetails))
      allPOAPCollectibleDetails.push(collectibleDetails);
    }
  }));
  LogToConsole("all POAPS",allPOAPCollectibleDetails);

});

export const refreshPOAPCollectibleDetails = createAsyncThunk("POAPCollections/refreshPOAPCollectibleDetails",async ({ collecionData, isCache = true }:  {collecionData: IPOAPCollectibleDetail, isCache?: boolean}, { getState, dispatch } ) => {

  let collectibleDetails = await getPOAPCollectibleDetails({ networkID : collecionData.chain , provider: getAnynetStaticProvider(collecionData.chain), collectibleData: collecionData  }, isCache)
  const state = getState() as RootState;
  const allEntityPassportDetails = state.Passports.AllEntityPassportDetails;

  allEntityPassportDetails?.forEach((passport) => {
    if(passport.whitelistedCollections.filter(collectible => collectible?.source?.toLowerCase() === collecionData?.address?.toLowerCase()).length > 0) {
      collectibleDetails.linkCollectible.push(passport?.address);
    }
  });

  if(collectibleDetails.name !== "") {
    dispatch(updatePhygitalData(collectibleDetails))
  }
});

export const CreatePOAPCollection = createAsyncThunk("POAPCollections/CreatePOAPCollection",async ({ networkID, provider, address, EntityAddress, collectibleData, dataURI, wallet, _transferable = true, chainID = networkID} : { networkID, provider, address, EntityAddress, collectibleData : IPOAPCollectibleDetail, dataURI :string, wallet, _transferable?: boolean, chainID?: NetworkId | "" }, { dispatch}): Promise<any> => {
  let name = collectibleData?.name?.trim() ?? "";
  const data = GetPOAPCreateCollectionMessage( 
                        EntityAddress, 
                        name, 
                        collectibleData?.symbol !== "" ?  collectibleData?.symbol : name, 
                        dataURI !== "" ? dataURI : "",
                        _transferable,
                        addresses[chainID].LayerZeroEndPoint,
                        Number(collectibleData.autoStartMint)
                    );

  if (address) {
    let msg: IMessageMetaData = {
      to: addresses[chainID].POAPCollectionFactory,
      wallet: wallet,
      data: data,
      networkID: chainID,
      provider: getAnynetStaticProvider(chainID)
    }
    LogToConsole(msg);

    let res = await dispatch(SendMetaTX(msg));
    if (res && res.payload?.eventLogs) {
      const createdCollection = GetPOAPCollectionCreatedLogData(res.payload?.eventLogs, chainID);
      if (createdCollection === ZERO_ADDRESS) {
        LogToConsoleError('POAP Creation: transaction failed...')
      }
      return createdCollection;
    }  
  }
  return ZERO_ADDRESS;
});

export const whitelistCollection = createAsyncThunk("Collections/whitelistCollection", async ({ networkID, provider, address, collectibleAddressA, collectibleAddressB, wallet, chainID }: { networkID, provider, address, collectibleAddressA, collectibleAddressB, wallet, chainID }, { dispatch }): Promise<any> => {
  
  const data = GetWhitelistCollectionMessage( collectibleAddressA, chainID, collectibleAddressB);

  if (address) {
    let msg: IMessageMetaData = {
      to: addresses[networkID].ExternalCollectionManager,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider
    }
    LogToConsole(msg);
    await dispatch(SendMetaTX(msg));
  }
});

export const delistCollection = createAsyncThunk("Collections/delistCollection", async ({ networkID, provider, address, collectibleAddressA, collectibleAddressB, wallet, chainID }: { networkID, provider, address, collectibleAddressA, collectibleAddressB, wallet, chainID }, { dispatch }): Promise<any> => {
  
  const data = GetDelistCollectionMessage( collectibleAddressA, chainID, collectibleAddressB);

  if (address) {
    let msg: IMessageMetaData = {
      to: addresses[networkID].ExternalCollectionManager,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider
    }
    LogToConsole(msg);
    await dispatch(SendMetaTX(msg));
  }
});


export interface ICollectibleSliceData {
    readonly AllPOAPCollectibleDetails: IPOAPCollectibleDetail [];
    readonly AllExternalCollectibleDetails: IExternalCollectibleDetail [];
    readonly loading: boolean;
    readonly poapsLoading: boolean;
  }

const initialState: ICollectibleSliceData = {
    AllPOAPCollectibleDetails: null,
    AllExternalCollectibleDetails: null,
    loading: false,
    poapsLoading: false,
};

const CollectibleSlice = createSlice({
  name: "CollectibleDetails",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
    updatePoapSocialData(state, action) {
      let passportData = state.AllPOAPCollectibleDetails?.find(obj => obj.address?.toLowerCase() === action.payload.address?.toLowerCase());
      passportData.socialMedia = action.payload.socialMedia;
    },
    setExternalCollectibleLoading(state, action) {
      state.poapsLoading = action.payload;
    },
    pushPhygitalData(state, action) {
      const allPOAPSData = state.AllPOAPCollectibleDetails ?? [];
      allPOAPSData.push(action.payload);
      state.AllPOAPCollectibleDetails = allPOAPSData?.sort((a, b) => Number(new Date(a.timeStamp).getTime()) - Number(new Date(b.timeStamp).getTime()));
    },
    updatePhygitalData(state, action) {
      let allCollectionData = state.AllPOAPCollectibleDetails ?? [];
      if(action.payload) {
        let collectionData = allCollectionData.find(obj => obj.address?.toLowerCase() === action.payload?.address?.toLowerCase());
        if(collectionData) {
          allCollectionData = allCollectionData.filter(obj => obj.address?.toLowerCase() !== collectionData.address?.toLowerCase());
          collectionData = { ...collectionData, ...action.payload };
        }
        else{
          collectionData = action.payload;
        }

        allCollectionData.push(collectionData)
      }
      state.AllPOAPCollectibleDetails = allCollectionData?.sort((a, b) => Number(new Date(a.timeStamp).getTime()) - Number(new Date(b.timeStamp).getTime()));
    },
    clearAllPOAPData(state) {
      state.AllPOAPCollectibleDetails = [];
      state.AllExternalCollectibleDetails = null;
    }

  },
  extraReducers: builder => {
    builder
        .addCase(loadExternalCollectibleDetails.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(loadExternalCollectibleDetails.fulfilled, (state, action) => {
            state.AllExternalCollectibleDetails = action.payload.AllExternalCollectibleDetails;
            state.loading = false;
        })
        .addCase(loadExternalCollectibleDetails.rejected, (state: { loading: boolean; }, { error }: any) => {
            state.loading = false;
            LogToConsoleError("loadExternalCollectibleDetails", error.name, error.message, error.stack);
        })
        .addCase(loadAllPOAPCollectibleDetails.pending, (state, action) => {
          // state.poapsLoading = true;
        })
        .addCase(loadAllPOAPCollectibleDetails.fulfilled, (state, action) => {
            state.poapsLoading = false;
        })
        .addCase(loadAllPOAPCollectibleDetails.rejected, (state, { error }: any) => {
            state.poapsLoading = false;
            LogToConsoleError("loadAllPOAPCollectibleDetails", error.name, error.message, error.stack);
        })
    },
});

export const CollectibleSliceReducer = CollectibleSlice.reducer;

const baseInfo = (state: RootState) => state.Collectibles;

export const { fetchAppSuccess, setExternalCollectibleLoading, updatePoapSocialData, pushPhygitalData, updatePhygitalData, clearAllPOAPData } = CollectibleSlice.actions;

export const getCollectibleState = createSelector(baseInfo, CollectibleSlice => CollectibleSlice);